body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .upload {
    max-width: 700px;
    max-height: 400px;
    margin-top: 150px;

    .ant-upload-drag {
      padding: 15px;
    }

    .ant-spin-container {
      display: grid;
      gap: 20px;
    }
  }

  .form {
    max-width: 700px;
    min-width: 300px;
    max-height: 400px;
    margin-top: 150px;
    display: grid;
  }
}